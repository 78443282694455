<script>
    let pageName = "Contact";
</script>

<div class="container" id="main">
    <h2>{pageName}</h2>
    <div class="container">
        <h3><a href="mailto:daveydevs@gmail.com">Email</a></h3>
        <h3><a href="https://github.com/DaveyDevs">GitHub</a></h3>
        <h3><a href="https://twitter.com/daveydevs">Twitter</a></h3>
    </div>
</div>
